<template>
  <flex fill class="height-100">
    <flex column class="px-20 pt-20">
      <iopr-header class="mb-30">
        <template #icon>
          <icon name="iopr" :size="32" class="font-color-primary" />
        </template>
        <template #title>
          <span class="font-uppercase font-bold font-size-20 ml-20">
            иопр пк
          </span>
        </template>
      </iopr-header>
      <flex class="font-color-gray mb-10">
        Выберите модуль, в котором хотите начать работу
      </flex>
    </flex>
    <flex fill class="px-20 overflow-auto">
      <template v-for="(module, i) in modulesSchema" :key="i">
        <router-link
          v-if="checkPermission(`${module.alias}.module`)"
          :to="module.path"
          class="flex align-items__center text-decoration-none font-color-primary bg-primary_l-10 border-radius-5 px-20 py-10 mb-10"
        >
          <icon :name="module.icon" :size="20" />
          <span class="ml-10 break-spaces">{{ module.name }}</span>
        </router-link>
      </template>
    </flex>
    <flex class="px-20 py-15">
      <uiButton class="font-uppercase width-100" @click="logout"
        >выход</uiButton
      >
    </flex>
  </flex>
</template>

<script>
import useAuth from '@/composition/useAuth';
import useUser from '@/composition/useUser';

export default {
  props: {
    modulesSchema: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const { logout } = useAuth();
    const { checkPermission } = useUser();

    return {
      logout,
      checkPermission
    };
  }
};
</script>
